import React, { useState, useRef, useEffect } from 'react';
import { OpenAI } from "openai-streams";
import './style.css';

function Chatbot({setIsEditGraph, setAIMode}) {
    const [retrieveOutput, setRetrieveOutput] = useState("");
    const [runCodeOutput, setRunCodeOutput] = useState("");
    const [showQuery, setShowQuery] = useState(false);

    const initialHistory = [
      {
        role: 'system',
        content:
          `당신은 인바디의 장비 문제를 해결해주는 엔지니어입니다. 인바디 장비와 관련한 질문만 받고, 무관한 질문은 거부합니다.`,
      },
    ]
  
    const [chatHistory, setChatHistory] = useState(initialHistory);
  
    const [isChattingDisabled, setIsDisabled] = useState(false);
    const chatHistoryRef = useRef(null);
    const initialRecommendedQuestion = [
      '전원이 안켜지면 어떻게 하나요?',
      '측정값에 차이가 있으면 어떻게 하나요?',
      '데이터를 엑셀 파일로 복사하려면 어떻게 해야하나요?',
      '전문가모드 측정 방법'
    ]
    const [recommendedQuestion, setRecommendedQuestion] = useState(initialRecommendedQuestion);
  
    const resetMessages = () => {
      setChatHistory(initialHistory);
      setRecommendedQuestion(initialRecommendedQuestion);
    }
  
    const [isQuestionOpen, setIsQuestionOpen] = useState(true);
  
    const toggleQuestionList = () => {
      setIsQuestionOpen(!isQuestionOpen);
    };
  
  
    useEffect(() => {
      // 메시지 리스트가 추가될 때마다 자동으로 스크롤을 맨 아래로 이동
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }, [chatHistory, recommendedQuestion]);
  
  
    const countTokens = (history) => {
      let count = 0;
      for (let i = 0; i < history.length; i++) {
        const tokens = history[i].content.split(' ');
        count += tokens.length;
      }
      return count;
    };
  
  
    const RecommendQuestion = (chatLog) => {
      var question_temp = '';
      
      while (countTokens(chatLog) > 400) {
        chatLog.splice(2, 2);
      }
      // console.log(chatLog);
  
      OpenAI("chat", {
        model: 'gpt-3.5-turbo',
        messages: [...chatLog,
        {
          role: 'user',
          content:
            `create 3 questions that i might be curious about regarding the answer you just provided, with each item seperated by a new line. Please only state the content of the recommended question. Reply in korean language.`
        }],
        temperature: 0.7,
        max_tokens: 2048,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      }, {
        apiKey: process.env.REACT_APP_OPENAI_API_KEY
      }).then((response) => {
        const reader = response.getReader();
        const decoder = new TextDecoder('utf-8');
        const streamData = (data) => {
          const { done, value } = data;
          if (done) {
            return;
          }
          const valueData = decoder.decode(value);
          question_temp = question_temp + valueData;
          const content = question_temp;
          const questions = content.split('\n');
  
          if (content.trim() !== '') {
            setRecommendedQuestion(questions);
          }
          
          reader.read().then(streamData);
        }
        reader.read().then(streamData);
      }).catch((err) => {
        console.log('Something wrong!');
        setRecommendedQuestion([]);
        console.log(err)
  
        // setTimeout(() => {
        //   RecommendQuestion(chatLog);
        // }, 2000);
      });
    }
  
    const handleSendMessage = async (e) => {
      e.preventDefault();
      if (isChattingDisabled) {
        alert('답변중입니다. 잠시만 기다려 주세요 :)');
        return;
      }
      const input = document.querySelector('.chat__input');
      if (input.value.trim() !== '') {
        const message = {
          role: 'user',
          content: input.value,
        };
        const updatedChatHistory = [...chatHistory, message];
        setChatHistory(updatedChatHistory);

        const requestBody = {
          query: input.value
        };

        let RAGResponse, RAGImage

        try {
          const response = await fetch('https://api.inbodychat.kr/search', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
          });

          if (!response.ok) {
            throw new Error('Failed to fetch response from RAG API');
          }

          const data = await response.json();
          RAGResponse = JSON.stringify(data.results[0].content);
          RAGImage = data.results[0].images? JSON.parse(data.results[0].images): [];
          console.log(RAGImage);

          setRetrieveOutput(RAGResponse);

        } catch (error) {
          console.error('Error:', error);
        }
  
        var updatedChatHistoryTemp = [...updatedChatHistory];
        updatedChatHistoryTemp.splice(updatedChatHistoryTemp.length-1,0,
          {
            role: 'system',
            content:
              `사용자의 질문에 대해 다음 내용을 가지고 응답해주세요: ${RAGResponse}`,
          })
        console.log(countTokens(updatedChatHistoryTemp));
        while (countTokens(updatedChatHistoryTemp) > 400) {
          updatedChatHistoryTemp.splice(2, 2);
        }
  
        console.log(updatedChatHistoryTemp);
        // console.log(countTokens(updatedChatHistoryTemp));
    
        setIsDisabled(true);
  
        setRecommendedQuestion([]);
        var responseMessage = '';
    
        function APICall(){
          OpenAI("chat", {
            model: 'gpt-3.5-turbo',
            messages: updatedChatHistoryTemp,
            temperature: 0.7,
            max_tokens: 2048,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
          }, {
            apiKey: process.env.REACT_APP_OPENAI_API_KEY
          }).then((response) => {
            const reader = response.getReader();
            const decoder = new TextDecoder('utf-8');
            const streamData = (data) => {
              const { done, value } = data;
              if (done) {
                const chatLog = [
                  ...updatedChatHistory,
                  ...RAGImage?.map((image, index) => {
                    return {
                      role: 'assistant',
                      content: "image: " + image,
                    };
                  }),
                  {
                    role: 'assistant',
                    content: responseMessage,
                  },
                ];
                setTimeout(() =>RecommendQuestion(chatLog), 500);
                setIsDisabled(false);
                return;
              }
              const valueData = decoder.decode(value);
              responseMessage = responseMessage + valueData;
              const content = responseMessage;
              if (content.trim() !== '') {
                setChatHistory([
                  ...updatedChatHistory,
                  ...RAGImage?.map((image, index) => {
                    return{
                      role: 'assistant',
                      content: "image: " + image,
                    };
                  }),
                  {
                    role: 'assistant',
                    content: content,
                  },
                ]);
              }
              reader.read().then(streamData);
            }
            reader.read().then(streamData);
          }).catch((err) => {
            console.log('Something wrong!');
            setTimeout(() => {
              APICall();
            }, 1000);
            console.log(err)
          });
        }
  
        APICall();
    
        input.value = '';
        responseMessage = '';
      }
    };
  
    const handleInputKeyPress = (event) => {
      if (event.key === 'Enter') {
        if (!event.shiftKey) {
          event.preventDefault();
          var button = document.getElementById('send');
          button.click();
        }
      }
    };
  
    const selectQuestion = (message) => {
      const input = document.querySelector('.chat__input');
      input.value = message;
      var button = document.getElementById('send');
      button.click();
    };

    const testCall = async () => {
        const requestBody = {
            query: document.getElementById('query').value,
        };

        try {
            const response = await fetch('https://api.inbodychat.kr/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch response from OpenAI API');
            }

            const data = await response.json();
            setRetrieveOutput(JSON.stringify(data.results));

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const codeCall = async () => {
      const requestBody = {
          code: document.getElementById('code').value,
      };

      try {
          const response = await fetch('https://api.inbodychat.kr/execute', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody)
          });

          if (!response.ok) {
              throw new Error('Failed to fetch response from the server');
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder('utf-8');

          setRunCodeOutput('');  // Clear previous output

          while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              const chunk = decoder.decode(value);
              setRunCodeOutput(prevOutput => prevOutput + chunk);
          }

      } catch (error) {
          console.error('Error:', error);
      }
  }

  const makeID = async () => {
    const requestBody = {
      username: document.getElementById('new-id').value,
      password: document.getElementById('new-pw').value,
    };

    try {
      const response = await fetch('http://localhost:5000/api/auth/register', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
          throw new Error('Failed to fetch response from the server');
      }
      
      const data = await response.json();
      console.log(data);

    } catch (error) {
        console.error('Error:', error);
    }
  }

  const LogIn = async () => {
    const requestBody = {
      username: document.getElementById('login-id').value,
      password: document.getElementById('login-pw').value,
    };

    try {
      const response = await fetch('http://localhost:5000/api/auth/login', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
          throw new Error('Failed to fetch response from the server');
      }
      
      const data = await response.json();
      console.log(data);

  } catch (error) {
      console.error('Error:', error);
  }
  }
  
    return (
        <div className="body">
          <header>
            <div className="header-left">
                <img id="logo" src="inbody-logo.png" alt="inbody logo" />
                <h1 className="header-title">TS Chatbot</h1>
            </div>
            <div className="header-right">
            </div>
            </header>
            {showQuery ? 
            <>
            <div className="retrieve-wrap">
                <div className="query-wrap">
                    <input id="query" className="query"></input>
                    <div className="query-button" onClick={testCall}>query</div>
                </div>
                <div className="retrieve-output">{retrieveOutput}</div>
            </div>
            <div className="codetest-wrap">
                <div className="codetest-input-wrap">
                    <textarea id="code" className="query"></textarea>
                    <div className="query-button" onClick={codeCall}>query</div>
                </div>
                <div className="retrieve-output">{runCodeOutput}</div>
            </div>
            <div className="codetest-wrap">
                <div className="codetest-input-wrap">
                    <input id="new-id" className="query"></input>
                    <input id="new-pw" className="query"></input>
                    <div className="query-button" onClick={makeID}>register</div>
                </div>
                <div className="retrieve-output">{runCodeOutput}</div>
            </div>
            <div className="codetest-wrap">
                <div className="codetest-input-wrap">
                    <input id="login-id" className="query"></input>
                    <input id="login-pw" className="query"></input>
                    <div className="query-button" onClick={LogIn}>log-in</div>
                </div>
                <div className="retrieve-output">{runCodeOutput}</div>
            </div>
            <div className="btn-wrap">
              <div className="query-btn" onClick={()=>setShowQuery(false)}>닫기</div>
            </div>
            </>:
            <div className="btn-wrap">
              <div className="query-btn" onClick={()=>setShowQuery(true)}>Test</div>
            </div>
            }
            <div className="btn-wrap">
              <div className="graph-edit-btn" onClick={()=>setIsEditGraph(true)}>Flow</div>
            </div>
            <div className="reset">
                <div className="reset-btn" onClick={resetMessages}>대화 초기화</div>
            </div>
            <div className="btn-wrap">
              <div className="mode-btn" onClick={()=>setAIMode(false)}>RuleMode</div>
            </div>
            <main>
                <div className="chat">
                <div className="chat__messages" ref={chatHistoryRef}>
                    <div className="chat__message chat__message--sent">
                        <div className="chat__message-bubble">
                            <p>안녕 너는 누구니 ?</p>
                        </div>
                    </div>
                    <div className="chat__message chat__message--received">
                        <div className="chat__message-bubble">
                            <p>반가워요 ! 저는 InBody의 인공지능 CS 엔지니어 Jeremy입니다. <br/>인바디 장비의 문제를 해결하는 방법에 대해서 답변해 드리고 있습니다. <br/>아래의 '추천 질문'을 클릭하시면 해당 내용에 대한 답변을 제공합니다.</p>
                        </div>
                    </div>
                    {chatHistory.map((message, index) => {
                    if (message.role === 'user') {
                        return (
                        <div key={index} className="chat__message chat__message--sent">
                            <div className="chat__message-bubble">
                            <p>{message.content}</p>
                            </div>
                        </div>
                        );
                    } else if (message.role === 'assistant') {
                        if (message.content.startsWith("image: ")) {
                          return (
                            <div key={index} className="chat__message chat__message--received">
                                <div className="chat__message-bubble">
                                <img src={message.content.slice(7)}  alt={message.content.slice(7)} />
                                </div>
                            </div>
                          )
                        }
                        return (
                        <div key={index} className="chat__message chat__message--received">
                            <div className="chat__message-bubble">
                            <p>{message.content}</p>
                            </div>
                        </div>
                        );
                    } else {
                        return null;
                    }
                    })}
                </div>
                
                {recommendedQuestion.length !== 0 && (
                    <div className="recommend_question">
                    <div className="question-arrow-wrap"  onClick={toggleQuestionList}>
                        <div className={`question-arrow ${isQuestionOpen ? 'down' : 'up'}`}>
                    </div>
                    </div>
                    {isQuestionOpen && (
                        <div className="question-wrap">
                        <div className="question-header"  onClick={toggleQuestionList}>
                            추천 질문
                        </div>
                        <div className="question-list">
                            {recommendedQuestion.map((message, index) => {
                            const handleClick = () => {
                                selectQuestion(message);
                            };
                            return (
                                <div key={index} className="question" onClick={handleClick}>
                                {message}
                                </div>
                            );
                            })}
                        </div>
                        </div>
                    )}
                    </div>
                )}
                <form className="chat__form" onSubmit={handleSendMessage}>
                    <textarea
                    className="chat__input"
                    id="input"
                    placeholder="메시지를 입력해 주세요."
                    style={{ resize: 'none' }}
                    rows="1"
                    onKeyDown={handleInputKeyPress}
                    ></textarea>
                    <button className="chat__send" id="send" type="submit">
                    전송
                    </button>
                </form>
                </div>
            </main>
        </div>
    );
}

export default Chatbot;