import React, { useState } from 'react';
import Chatbot from './Chatbot';
import GraphEditorRFWithProvider from './GraphEditorRF'
import Rulebot from './Rulebot'

function App() {
  const [isEditGraph, setIsEditGraph] = useState(false);
  const [AIMode, setAIMode] = useState(false);
  return (
    isEditGraph ? 
    <GraphEditorRFWithProvider setIsEditGraph={setIsEditGraph}/> :
    AIMode ?
    <Chatbot setIsEditGraph={setIsEditGraph} setAIMode={setAIMode}/>:
    <Rulebot setIsEditGraph={setIsEditGraph} setAIMode={setAIMode}/>
  );
}

export default App;