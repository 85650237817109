import React, { useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { useNodeStore } from './store.js';
import './components/Node.css';

// Debounce 함수
function debounce(func, delay) {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), delay);
    };
}

function CustomRFNode({ data }) {
    const { setNodeTitle, setOptionTitle, appendOption, removeOption, setConnect, startConnect, removeConnect, removeNode } = useNodeStore();
    
    const nodeIndex = data.nodeIndex;
    
    // 임시로 타이틀을 로컬 상태로 관리
    const [nodeTitle, setNodeTitleState] = useState(data.title);
    const [optionTitles, setOptionTitles] = useState(data.options.map(option => option.title));

    useEffect(()=> {
        setOptionTitles(data.options.map(option => option.title))
    }, [setConnect, startConnect, data])

    // 노드 타이틀 변경 핸들러
    const handleChangeNodeTitle = useCallback(debounce((title) => {
        setNodeTitle(nodeIndex, title);
    }, 300), [nodeIndex, setNodeTitle]);

    // 옵션 타이틀 변경 핸들러
    const handleChangeOptionTitle = useCallback(debounce((optionIndex, title) => {
        setOptionTitle(nodeIndex, optionIndex, title);
    }, 300), [nodeIndex, setOptionTitle]);

    return (
        <div className="node" onClick={() => setConnect(nodeIndex)}>
            <Handle
                type="target"
                position={Position.Top}
            />
            <div className="node-header-wrap">
                <div className="node-index">{nodeIndex}</div>
                <div className="remove-btn" onClick={() => removeNode(nodeIndex)}>×</div>
            </div>
            <textarea 
                onChange={(e) => {
                    const value = e.target.value;
                    setNodeTitleState(value); // 로컬 상태 업데이트
                    handleChangeNodeTitle(value); // 디바운스 후 상태 업데이트
                }} 
                value={nodeTitle} 
                className="node-title nodrag nowheel"
                placeholder="Question"
            />
            <div className="options-wrap nodrag">
                {optionTitles.map((title, optionIndex) => (
                    <div 
                        key={optionIndex} 
                        className="option-wrap" 
                    >
                        <textarea 
                            onChange={(e) => {
                                const value = e.target.value;
                                const newTitles = [...optionTitles];
                                newTitles[optionIndex] = value;
                                setOptionTitles(newTitles); // 로컬 상태 업데이트
                                handleChangeOptionTitle(optionIndex, value); // 디바운스 후 상태 업데이트
                            }} 
                            value={title} 
                            className="node-option nowheel" 
                            placeholder="Option Name"
                        />
                        <div className="option-btns">
                            <div className="remove-option" onClick={() => removeOption(nodeIndex, optionIndex)}>×</div>
                            {data.options[optionIndex]?.connectedTo !== null ? (
                                <div className="set-connection" onClick={() => removeConnect(nodeIndex, optionIndex)}>×</div>
                            ) : (
                                <div className="set-connection" onClick={() => startConnect(nodeIndex, optionIndex)}>→</div>
                            )}
                        </div>
                    </div>
                ))}
                <div className="append-option" onClick={() => appendOption(nodeIndex)}>＋</div>
            </div>
            {data.options.map((option, optionIndex) => (
                <Handle
                    key={optionIndex} 
                    type="source"
                    position={Position.Bottom}
                    id={String.fromCharCode(97 + optionIndex)}
                    style={{left: `${((optionIndex+1)/(data.options.length+1)*100)}%`}}
                />
            ))}
        </div>
    );
}

export default CustomRFNode;
